import React from "react";
import EnhancedTable from "../../tables/EnhancedTable";
import {TaskAPI} from "../../../http/api/TaskAPI";
import history from "../../../history";

const headCells = [
    { id: 'id', label: 'ID', type: "number", sortBy: 'id' },
    { id: 'created_at', label: 'Created', type: "date", sortBy: 'created_at' },
    { id: 'number', label: 'Number', type: "string", sortBy: 'number' },
    { id: 'version', label: 'Version', type: "number", sortBy: 'version' },
    { id: 'checker_id', label: 'Checker ID', type: "number", sortBy: 'checker_id' },
    { id: 'is_interactive', label: 'Is interactive', type: "bool", sortBy: 'is_interactive' },
];

export const TaskList = (props) => {
    const taskAPI = new TaskAPI();

    const deleteMany = async (ids) => {
        for (let id of ids) {
            await taskAPI.DeleteTask(id);
        }
        window.location.reload();
    }

    return (
        <EnhancedTable
            title={"Tasks"}
            headCells={headCells}
            getDataCallback={taskAPI.QueryTask}
            deleteManyCallback={(ids) => deleteMany(ids)}
            viewCallback={(id) => history.push({pathname: `/task/${id}`})}
            editCallback={(id) => history.push(`/task/${id}/edit`)}
            createCallback={() => history.push(`/tasks/new`)}
            selectionID={"id"}
        />
    )
}
