import React, {useEffect, useState} from "react";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {TaskAPI} from "../../../http/api/TaskAPI";
import history from "../../../history";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {CheckerAPI} from "../../../http/api/CheckerAPI";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Card} from "@material-ui/core";


export const TaskEdit= (props) => {
    const classes = useStyles();

    let [task, setTask] = useState(null);

    let [number, setNumber] = useState('');
    let [version, setVersion] = useState('');
    let [checkerID, setCheckerID] = useState('');
    let [isInteractive, setIsInteractive] = useState(false);

    let [checkers, setCheckers] = useState([]);

    useEffect(() => {
        async function fetchCheckers() {
            const api = new CheckerAPI();
            return await api.QueryChecker(0, 10000);
        }

        fetchCheckers().then((c) => {
            setCheckers(c)
        })
    }, [props]);

    useEffect(() => {
        async function fetchTask(id) {
            const api = new TaskAPI();
            return await api.RetrieveTask(id);
        }

        let taskID = props.match.params.id;
        fetchTask(taskID).then((s) => {
            setTask(s);
            setNumber(s.number);
            setVersion(s.version);
            setIsInteractive(s["is_interactive"]);
            setCheckerID(s["checker_id"]);
        })
    }, [props]);

    const updateTask = async (event) => {
        event.preventDefault();

        let t = {};
        t["number"] = number;
        t["version"] = version;
        t["checker_id"] = checkerID;
        t["is_interactive"] = isInteractive;

        const api = new TaskAPI();
        await api.UpdateTask(task.id, t);

        history.push({pathname: `/task/${task.id}`});
    }

    return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                Edit task
            </Typography>
            <br/>
            <form onSubmit={(event => updateTask(event))}>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="number"
                        label="Number"
                        name="number"
                        value={number}
                        onChange={event => setNumber(event.target.value)}
                    />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="version"
                        label="Version"
                        name="version"
                        value={version}
                        defaultValue="1"
                        onChange={event => setVersion(event.target.value)}
                    />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                    <InputLabel id="select-checker-label">Checker</InputLabel>
                    <Select
                        labelId="select-checker-label"
                        id="select-checker"
                        value={checkerID}
                        onChange={(event) => setCheckerID(event.target.value)}
                    >
                        {checkers.map(c => {
                            return <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isInteractive}
                                onChange={() => setIsInteractive(!isInteractive)}
                                name="is-interactive"
                            />
                        }
                        label="Is interactive"
                    />
                </FormControl>
                <br/><br/>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </form>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 640,
        padding: 20
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
        maxWidth: 720,
    },
}));
