import React, {useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {ProgrammingLanguageAPI} from "../../../http/api/ProgrammingLanguageAPI";
import {ViewFooter} from "../ViewFooter";
import {Card} from "@material-ui/core";


export const ProgrammingLanguageView = (props) => {
    const classes = useStyles();
    let [pl, setProgrammingLanguage] = React.useState(null);

    useEffect(() => {
        async function fetchProgrammingLanguage(id) {
            const api = new ProgrammingLanguageAPI();
            return await api.RetrieveProgrammingLanguage(id)
        }

        let plID = props.match.params.id;
        fetchProgrammingLanguage(plID).then((s) => {
            setProgrammingLanguage(s)
        })
    }, [props]);

    if (pl === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                {pl["name"]} (v{pl["version"]})
            </Typography>
            <br/>
            <Typography variant="body2" component="p">
                File extension: <strong>{pl["file_extension"]}</strong> <br/>
                Image name: <strong>{pl["image_name"]}</strong> <br/>
                Compilation string: <strong>{pl["compilation_string"]}</strong> <br/>
                Additional info: <strong>{pl["additional_info"]}</strong>
            </Typography>
            <br/>
            <ViewFooter entity={pl}/>
        </Card>
    );
}

const useStyles = makeStyles(() => ({
    card: {
        maxWidth: 880,
        padding: 20
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}));
