import React, {useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const ReadMore = props => {
    const [isExpanded, setIsExpanded] = useState(false)
    const classes = useStyles()

    const getTextBeginning = () => {
        let text = props.text
        if (text.split("\n").length >= props.maxLinesNumber) text = text.split("\n").slice(0, props.maxLinesNumber).join("\n")
        if (text.length >= props.maxLength) text = text.substring(0, props.maxLength)
        return text
    }

    return (
        props.text.length <= props.maxLength && props.text.split("\n").length <= props.maxLinesNumber + 1 ? <p className={classes.p}>{props.text}</p> :
            <>
                <p className={classes.p}>{isExpanded ? props.text : getTextBeginning()}{isExpanded ? "" : "..."}</p>
                <Button className={classes.readMoreBtn} variant="text" onClick={() => setIsExpanded(prev => !prev)}>
                    {isExpanded ? "Read less" : "Read more"}
                </Button>
            </>
    )
}

const useStyles = makeStyles((theme) => ({
    p: {
        margin: 0,
        whiteSpace: "pre-wrap"
    },
    readMoreBtn: {
        display: "block",
        margin: "0 auto",
        color: theme.palette.primary.dark,
        fontWeight: "bold"
    }
}))

ReadMore.propTypes = {
    text: PropTypes.string.isRequired,
    maxLinesNumber: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired
}