import React, {useEffect, useState} from "react";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {CheckerAPI} from "../../../http/api/CheckerAPI";
import history from "../../../history";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-c_cpp";
import {Card} from "@material-ui/core";


export const CheckerEdit = (props) => {
    const classes = useStyles();

    let [checker, setChecker] = useState(null);
    let [name, setName] = useState('');
    let [version, setVersion] = useState('');
    let [sourceCode, setSourceCode] = useState('');

    useEffect(() => {
        async function fetchChecker(id) {
            const api = new CheckerAPI();
            return await api.RetrieveChecker(id);
        }

        let checkerID = props.match.params.id;
        fetchChecker(checkerID).then((s) => {
            setChecker(s);
            setName(s.name);
            setVersion(s.version);
            setSourceCode(s["source_code"]);
        })
    }, [props]);

    const updateChecker = async (event) => {
        event.preventDefault();

        checker["name"] = name;
        checker["version"] = version;
        checker["source_code"] = sourceCode;

        const api = new CheckerAPI();
        await api.UpdateChecker(checker.id, checker);

        history.push({pathname: `/checker/${checker.id}`});
    }

    return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                Update checker
            </Typography>
            <br/>
            <form onSubmit={(event => updateChecker(event))}>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="name"
                        label="Name"
                        name="name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="version"
                        label="Version"
                        name="version"
                        value={version}
                        onChange={event => setVersion(event.target.value)}
                    />
                </FormControl>
                <br/>
                <AceEditor
                    mode="c_cpp"
                    theme="textmate"
                    onChange={(value) => {
                        setSourceCode(value)
                    }}
                    value={sourceCode}
                    name="codeEditor"
                    width={"100%"}
                    editorProps={{$blockScrolling: true}}
                />
                <br/><br/>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </form>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 1200,
        padding: 20
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
        maxWidth: 720,
    },
}));
