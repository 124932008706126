import {lighten, makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import BuildIcon from "@material-ui/icons/Build";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React from "react";


export const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {numSelected, selected, createCallback, deleteManyCallback, rebuildManyCallback, title} = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
            {numSelected > 0 ? (
                <div className={classes.btnGroup}>
                    {rebuildManyCallback ?
                        <Tooltip title="Rebuild">
                            <IconButton aria-label="rebuild" onClick={() => rebuildManyCallback(selected)}>
                                <BuildIcon/>
                            </IconButton>
                        </Tooltip> : ''
                    }
                    {deleteManyCallback ?
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteManyCallback(selected)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> : ''
                    }
                </div>
            ) : createCallback ?
                <Button variant="contained" color="primary" onClick={() => createCallback()}>
                    <AddIcon/> New
                </Button> : ''
            }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    createCallback: PropTypes.func,
    title: PropTypes.string
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    btnGroup: {
        display: "flex"
    }
}));
