import {HTTP} from "../http-common";

export class CheckerAPI {
    QueryChecker(start, end, sort='id', order='DESC') {
        order = order.toUpperCase()
        return new Promise((resolve) => {
            HTTP.axios.get(`/checker?_start=${start}&_end=${end}&_order=${order}&_sort=${sort}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    CreateChecker(checker) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/checker`, checker)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RetrieveChecker(id) {
        return new Promise((resolve) => {
            HTTP.axios.get(`/checker/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    UpdateChecker(id, checker) {
        return new Promise((resolve) => {
            HTTP.axios.put(`/checker/${id}`, checker)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    DeleteChecker(id) {
        return new Promise((resolve) => {
            HTTP.axios.delete(`/checker/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RebuildChecker = async (id) => {
        let checker = await this.RetrieveChecker(id);
        return await this.UpdateChecker(id, checker);
    }
}
