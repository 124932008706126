import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {NestedRouter} from "../components/menu/NestedRouter";
import {ContentWrapper} from "../components/menu/ContentWrapper";

/*
 Основной контент отрисовывается здесь.
 */
export const IndexPage = (props) => {
    return (
        <div style={{display: "flex"}}>
            <CssBaseline />
            <ContentWrapper />
            <NestedRouter />
        </div>
    );
}

