export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function mapCompilerToCode(compiler) {
    switch (compiler) {
        case "g++":
            return "cpp";
        case "mono":
            return "csharp";
        case "java":
            return "java";
        case "python":
            return "python";
        case "pascal":
            return "pascal";
        default:
            return "cpp";
    }
}
