import React from "react";
import history from "../../history";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import ListItemText from "@material-ui/core/ListItemText";
import TranslateIcon from '@material-ui/icons/Translate';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {makeStyles} from "@material-ui/core/styles";

/*
 Меню сайдбара
 */
export const MainMenu = (props) => {
    const classes = useStyles()

    return (
        <List>
            <ListItem className={classes.listItem} button key={"Checker"} onClick={() => history.push({pathname:'/checkers'})}>
                <ListItemIcon>{<CloudDoneIcon/>}</ListItemIcon>
                <ListItemText primary={"Checkers"}/>
            </ListItem>
            <ListItem className={classes.listItem} button key={"Languages"} onClick={() => history.push({pathname:'/programming_languages'})}>
                <ListItemIcon>{<TranslateIcon/>}</ListItemIcon>
                <ListItemText primary={"Languages"}/>
            </ListItem>
            <ListItem className={classes.listItem} button key={"Solutions"} onClick={() => history.push({pathname:'/solutions'})}>
                <ListItemIcon>{<EmojiObjectsIcon/>}</ListItemIcon>
                <ListItemText primary={"Solutions"}/>
            </ListItem>
            <ListItem className={classes.listItem} button key={"Tasks"} onClick={() => history.push({pathname:'/tasks'})}>
                <ListItemIcon>{<AssignmentIcon/>}</ListItemIcon>
                <ListItemText primary={"Tasks"}/>
            </ListItem>
        </List>
    )
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: "21px"
    }
}));
