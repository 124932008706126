import React, {useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {SolutionAPI} from "../../../http/api/SolutionAPI";
import {ViewFooter} from "../ViewFooter";
import SyntaxHighlighter from "react-syntax-highlighter";
import xcode from "react-syntax-highlighter/dist/cjs/styles/hljs/xcode";
import {mapCompilerToCode} from "../../../helpers";
import {Card, Divider} from "@material-ui/core";
import history from "../../../history";


export const SolutionView = (props) => {
    const classes = useStyles();
    let [solution, setSolution] = React.useState(null);

    useEffect(() => {
        async function fetchSolution(uuid) {
            const api = new SolutionAPI();
            return await api.RetrieveSolution(uuid)
        }

        let solutionUUID = props.match.params.uuid;
        fetchSolution(solutionUUID).then((s) => {
            setSolution(s)
        })
    }, [props]);

    if (solution === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                Solution {solution["solution_uuid"]}
            </Typography>
            <br/>
            <SyntaxHighlighter language={mapCompilerToCode(solution["programming_language"]["compiler"])} style={xcode}>
                {solution["source_code"]}
            </SyntaxHighlighter>
            <Divider/>
            <br/>
            <Typography variant="h6" component="h1">
                <span onClick={() => history.push(`/task/${solution["task"].id}`)}
                      className="link-style">Task №{solution["task"]["number"]}</span>
                &nbsp;({solution["programming_language"].name})
            </Typography>
            <br/>
            <ViewFooter entity={solution}/>
        </Card>
    );
}

const useStyles = makeStyles(() => ({
    card: {
        maxWidth: 1000,
        padding: 20
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}));
