import {HTTP} from "../http-common";

export class ProgrammingLanguageAPI {
    QueryProgrammingLanguage(start, end, sort='id', order='DESC') {
        order = order.toUpperCase()
        return new Promise((resolve) => {
            HTTP.axios.get(`/programming_language?_start=${start}&_end=${end}&_order=${order}&_sort=${sort}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    CreateProgrammingLanguage(programmingLanguage) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/programming_language`, programmingLanguage)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RetrieveProgrammingLanguage(id) {
        return new Promise((resolve) => {
            HTTP.axios.get(`/programming_language/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    UpdateProgrammingLanguage(id, programmingLanguage) {
        return new Promise((resolve) => {
            HTTP.axios.put(`/programming_language/${id}`, programmingLanguage)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    DeleteProgrammingLanguage(id) {
        return new Promise((resolve) => {
            HTTP.axios.delete(`/programming_language/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }
}
