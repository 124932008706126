import React from "react";
import EnhancedTable from "../../tables/EnhancedTable";
import {ProgrammingLanguageAPI} from "../../../http/api/ProgrammingLanguageAPI";
import history from "../../../history";

const headCells = [
    { id: 'id', label: 'ID', type: "number", sortBy: 'id' },
    { id: 'name', label: 'Name', type: "string", sortBy: 'name' },
    { id: 'version', label: 'Version', type: "number", sortBy: 'version' },
    { id: 'compiler', label: 'Compiler', type: "string", sortBy: 'compiler' },
];

export const ProgrammingLanguageList = (props) => {
    const programmingLanguageAPI = new ProgrammingLanguageAPI();

    return (
        <EnhancedTable title={"Programming Languages"}
                        headCells={headCells}
                        getDataCallback={programmingLanguageAPI.QueryProgrammingLanguage}
                        viewCallback={(id) => history.push(`/programming_language/${id}`)}
        />
    )
}
