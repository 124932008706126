import {HTTP} from "../http-common";

export class TaskAPI {
    QueryTask(start, end, sort='id', order='DESC') {
        order = order.toUpperCase()
        return new Promise((resolve) => {
            HTTP.axios.get(`/task?_start=${start}&_end=${end}&_order=${order}&_sort=${sort}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    CreateTask(task) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/task`, task)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RetrieveTask(id) {
        return new Promise((resolve) => {
            HTTP.axios.get(`/task/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    UpdateTask(id, task) {
        return new Promise((resolve) => {
            HTTP.axios.put(`/task/${id}`, task)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    DeleteTask(id) {
        return new Promise((resolve) => {
            HTTP.axios.delete(`/task/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }
}
