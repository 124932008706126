import {HTTP} from "../http-common";

export class TestCaseAPI {
    QueryTestCase(start, end, sort='id', order='desc') {
        return new Promise((resolve) => {
            HTTP.axios.get(`/test_case?_start=${start}&_end=${end}&_order=${order}&_sort=${sort}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    QueryTestCaseOfOneTask(start, end, taskId, sort='id', order='desc') {
        return new Promise((resolve) => {
            HTTP.axios.get(`/test_case?task=${taskId}&_start=${start}&_end=${end}&_order=${order}&_sort=${sort}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    CreateTestCase(testCase) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/test_case`, testCase)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RetrieveTestCase(id) {
        return new Promise((resolve) => {
            HTTP.axios.get(`/test_case/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    UpdateTestCase(id, testCase) {
        return new Promise((resolve) => {
            HTTP.axios.put(`/test_case/${id}`, testCase)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    DeleteTestCase(id) {
        return new Promise((resolve) => {
            HTTP.axios.delete(`/test_case/${id}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    ReorderTestCase(id, newOrderArray) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/test_case/reorder?task=${id}`, newOrderArray)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }
}
