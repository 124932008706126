import React from "react";
import EnhancedTable from "../../tables/EnhancedTable";
import {CheckerAPI} from "../../../http/api/CheckerAPI";
import history from "../../../history";

const headCells = [
    { id: 'id', label: 'ID', type: "number", sortBy: 'id' },
    { id: 'updated_at', label: 'Last update', type: "date", sortBy: 'updated_at' },
    { id: 'name', label: 'Name', type: "string", sortBy: 'name' },
    { id: 'version', label: 'Version', type: "number", sortBy: 'version' },
    { id: 'build_finished', label: 'Build finished?', type: "bool", sortBy: 'build_finished' },
    { id: 'failed_build_message', label: 'Message', type: "string", sortBy: 'failed_build_message'}
];

export const CheckerList = (props) => {
    const checkerAPI = new CheckerAPI();

    const deleteMany = async (ids) => {
        for (let id of ids) {
            await checkerAPI.DeleteChecker(id);
        }
        window.location.reload();
    }

    const rebuildMany = async (ids) => {
        for (let id of ids) {
            await checkerAPI.RebuildChecker(id);
        }
        window.location.reload();
    }

    return (
        <EnhancedTable
            title={"Checkers"}
            headCells={headCells}
            getDataCallback={checkerAPI.QueryChecker}
            deleteManyCallback={(ids) => deleteMany(ids)}
            rebuildManyCallback={(ids) => rebuildMany(ids)}
            viewCallback={(id) => history.push(`/checker/${id}`)}
            editCallback={(id) => history.push(`/checker/${id}/edit`)}
            createCallback={() => history.push(`/checkers/new`)}
            selectionID={"id"}
        />
    )
}
