import React from "react";
import ej from '../../assets/ej.png';
import noIdeaMeme from '../../assets/noIdeaMeme.png'
import hahaYesMeme from '../../assets/hahaYesMeme.jpg'
import hahaNoMeme from '../../assets/hahaNoMeme.jpg'
import brotherMeme from '../../assets/brotherMeme.jpg'
import spongeMeme from '../../assets/spongMeme.png'
import {makeStyles} from "@material-ui/core/styles";


export const Placeholder = () => {
    const classes = useStyles()

    return (
        <div className={classes.memeWrapper}>
            <div className={classes.memeBlock}>
                <img alt='ej' src={ej} width={400} height={300} />
                <br />
                <span>Это не те дроиды, которых вы ищете</span>
            </div>
            <div className={classes.memeBlock}>
                <img alt='ej' src={noIdeaMeme} width={400} height={300} />
            </div>
            <div className={classes.memeBlock}>
                <img alt='ej' src={brotherMeme} width={400} height={300} />
            </div>
            <div className={classes.memeBlock}>
                <img alt='ej' src={spongeMeme} width={400} height={300} />
            </div>
            <div className={classes.memeBlock}>
                <img alt='ej' src={hahaYesMeme} width={400} height={300} />
            </div>
            <div className={classes.memeBlock}>
                <img alt='ej' src={hahaNoMeme} width={400} height={300} />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    memeWrapper: {
        display: "flex",
        flexWrap: "wrap"
    },
    memeBlock: {
        margin: "20px"
    }
}));

