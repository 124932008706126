import React, {useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {CheckerAPI} from "../../../http/api/CheckerAPI";
import {ViewFooter} from "../ViewFooter";
import Button from "@material-ui/core/Button";
import history from "../../../history";
import {ButtonGroup, Card} from "@material-ui/core";
import SyntaxHighlighter from "react-syntax-highlighter";
import xcode from "react-syntax-highlighter/dist/cjs/styles/hljs/xcode";


export const CheckerView = (props) => {
    const classes = useStyles();
    let [checker, setChecker] = React.useState(null);

    useEffect(() => {
        async function fetchChecker(id) {
            const api = new CheckerAPI();
            return await api.RetrieveChecker(id);
        }

        let checkerID = props.match.params.id;
        fetchChecker(checkerID).then((s) => {
            setChecker(s);
        })
    }, [props]);

    let deleteChecker = async () => {
        const api = new CheckerAPI();
        await api.DeleteChecker(checker.id);
        history.push('/checkers');
    }

    const rebuildChecker = async () => {
        const api = new CheckerAPI()
        await api.RebuildChecker(checker.id)
        window.location.reload();
    }

    if (checker === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                Checker {checker["name"]} (v{checker["version"]})
            </Typography>
            <br/>
            <ButtonGroup>
                <Button variant="contained" className={classes.rebuildBtn} onClick={rebuildChecker}>
                    Rebuild
                </Button>
                <Button variant="contained" color="primary" onClick={() => history.push(`/checker/${checker.id}/edit`)}>
                    Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => deleteChecker()}>
                    Delete
                </Button>
            </ButtonGroup>
            <br/>
            <SyntaxHighlighter language={"cpp"} style={xcode}>
                {checker["source_code"]}
            </SyntaxHighlighter>
            <br/><br/>
            <Typography variant="body2">
                Build {checker["build_finished"] ? "finished" : "in process"} <br/>
                {checker["failed_build_message"] ?
                    <span style={{color: "red"}}>{checker["failed_build_message"]}</span>
                    :
                    <span style={{color: "green"}}>Success</span>
                }
            </Typography>
            <ViewFooter entity={checker}/>
        </Card>
    );
}

const useStyles = makeStyles(() => ({
    card: {
        maxWidth: 1200,
        padding: 20
    },
    rebuildBtn: {
        backgroundColor: "#ff5722",
        '&:hover': {
            backgroundColor: "#b23c17"
        },
        color: "#fff"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}));
