import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';
import {EnhancedTableHead} from "./EnhancedTableHead";
import {EnhancedTableToolbar} from "./EnhancedTableToolbar";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(props.selectionID);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const {
        headCells, getDataCallback, createCallback, deleteManyCallback, rebuildManyCallback,
        viewCallback, editCallback, title, selectionID, taskId
    } = props;

    let additionalCells = 0;
    if (editCallback) additionalCells++;
    if (viewCallback) additionalCells++;

    useEffect(() => {
        if (!isLoaded) {
            (async function getData() {
                await getDataCallback(page * rowsPerPage, (page + 1) * rowsPerPage, selectionID, order, taskId)
                    .then((data) => setRows(data));
            })();

            setIsLoaded(true);
        }
    }, [isLoaded, getDataCallback, headCells, order, page, rowsPerPage, selectionID, taskId]);

    const updateData = React.useCallback((newPage, rowsPerPage, orderBy, order, taskId) => {
        (async function getData() {
            await getDataCallback(newPage * rowsPerPage, (newPage + 1) * rowsPerPage, orderBy, order, taskId)
                .then((data) => setRows(data));
        })();
    }, [getDataCallback])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        let newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        updateData(page, rowsPerPage, property, newOrder);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelects = rows.map((n) => n[selectionID]);
            setSelected(newSelects);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        updateData(newPage, rowsPerPage, orderBy, order);
    };

    const handleChangeRowsPerPage = (event) => {
        let pp = parseInt(event.target.value, 10);
        setRowsPerPage(pp);
        setPage(0);
        updateData(page, pp, orderBy, order);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar title={title}
                                      numSelected={selected.length}
                                      selected={selected}
                                      createCallback={createCallback}
                                      deleteManyCallback={deleteManyCallback}
                                      rebuildManyCallback={rebuildManyCallback}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            title={title}
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={headCells}
                            additionalCells={additionalCells}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row[selectionID]);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row[selectionID])}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row[selectionID]}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{'aria-labelledby': labelId}}
                                            />
                                        </TableCell>
                                        {
                                            headCells.map((hc, i) => {
                                                if (row[hc.id]) {
                                                    if (i !== 0) return <TableCell key={i} align={"center"}>
                                                        {
                                                            hc.type === "date" ?
                                                                moment(row[hc.id]).format("LLL")
                                                                :
                                                                row[hc.id].toString()
                                                        }
                                                    </TableCell>
                                                    else return <TableCell component="th" scope="row" padding="none" key={i} align={"center"}>
                                                        {row[hc.id].toString()}
                                                    </TableCell>
                                                } else return <TableCell component="th" scope="row" padding="none" key={i} align={"center"}>-</TableCell>
                                            })
                                        }
                                        {viewCallback ?
                                            <TableCell align="right" component="th" scope="row">
                                                <Button onClick={() => viewCallback(row[selectionID])}><VisibilityIcon/></Button>
                                            </TableCell>
                                            : null
                                        }
                                        {editCallback ?
                                            <TableCell align="right" component="th" scope="row">
                                                <Button
                                                    onClick={() => editCallback(row[selectionID])}><CreateIcon/></Button>
                                            </TableCell>
                                            : null
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={+localStorage.getItem("total-count")}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

EnhancedTable.propTypes = {
    headCells: PropTypes.array.isRequired,
    getDataCallback: PropTypes.func.isRequired,
    createCallback: PropTypes.func,
    deleteManyCallback: PropTypes.func,
    rebuildManyCallback: PropTypes.func, // only for checker
    editCallback: PropTypes.func,
    viewCallback: PropTypes.func,
    title: PropTypes.string,
    selectionID: PropTypes.string,
};

EnhancedTable.defaultProps = {
    selectionID: "id"
}
