import React, {useState} from "react";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import {CheckerAPI} from "../../../http/api/CheckerAPI";
import history from "../../../history";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AceEditor from "react-ace";
import {Card} from "@material-ui/core";


export const CheckerCreate = () => {
    const classes = useStyles();

    let [name, setName] = useState('');
    let [version, setVersion] = useState('');
    let [sourceCode, setSourceCode] = useState('');

    const createChecker = async (event) => {
        event.preventDefault();

        let checker = {};
        checker["name"] = name;
        checker["version"] = version;
        checker["source_code"] = sourceCode;

        const api = new CheckerAPI();
        let r = await api.CreateChecker(checker);

        history.push({pathname: `/checker/${r.id}`, state: {checker: r}});
    }

    return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                New checker
            </Typography>
            <br/>
            <form onSubmit={(event => createChecker(event))}>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="name"
                        label="Name"
                        name="name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                </FormControl>
                <br/>
                <FormControl className={classes.formControl}>
                    <TextField
                        required
                        id="version"
                        label="Version"
                        name="version"
                        value={version}
                        defaultValue="1"
                        onChange={event => setVersion(event.target.value)}
                    />
                </FormControl>
                <br/><br/>
                <AceEditor
                    mode="c_cpp"
                    theme="textmate"
                    onChange={(value) => {
                        setSourceCode(value)
                    }}
                    value={sourceCode}
                    name="codeEditor"
                    width={"100%"}
                    editorProps={{$blockScrolling: true}}
                />
                <br/><br/>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Create
                </Button>
            </form>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 1100,
        padding: 20
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
        maxWidth: 720,
    },
}));
