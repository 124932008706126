import React from "react";
import EnhancedTable from "../../tables/EnhancedTable";
import {SolutionAPI} from "../../../http/api/SolutionAPI";
import history from "../../../history";

const headCells = [
    { id: 'solution_uuid', label: 'UUID', type: "string", sortBy: 'solution_uuid' },
    { id: 'created_at', label: 'Created', type: "date", sortBy: 'created_at' },
    { id: 'is_visible', label: 'Is visible', type: "bool", sortBy: 'is_visible' },
];

export const SolutionListOfOneTask = (props) => {
    const solutionAPI = new SolutionAPI();

    return (
        <EnhancedTable
            title={"Solutions"}
            headCells={headCells}
            getDataCallback={solutionAPI.QuerySolution}
            viewCallback={(uuid) => history.push(`/solution/${uuid}`)}
            selectionID="solution_uuid"
            taskId={props.match.params.id}
        />
    )
}
