import React, {useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ArrowUpwardRounded} from "@material-ui/icons";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {ReadMore} from "../../common/ReadMore";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {TestCaseAPI} from "../../../http/api/TestCaseAPI";
import history from "../../../history";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";

export const AddFileTestCase = (props) => {
    const classes = useStyles()
    const inputUpload = useRef(null)
    const [testCases, setTestCases] = useState([])
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const inputFileOnChange = (e) => {
        const files = Array.from(e.target.files)
        files.forEach(file => {
            if (file.type !== "text/plain") {
                setIsSnackbarOpen(true)
                return
            }
            const regex = new RegExp("(input|output)([0-9]+)\\.txt")
            if (!regex.test(file.name)) {
                setIsSnackbarOpen(true)
                return
            }
            file.text().then(text => {
                const result = file.name.match(regex)
                const dataType = result[1]   // input or output
                const testCaseNumber = result[2]

                const curTestCases = testCases
                const testCaseIndex = curTestCases.findIndex(x => x.number === +testCaseNumber)
                if (testCaseIndex === -1)
                    curTestCases.push({
                        number: +testCaseNumber,
                        [dataType]: text
                    })
                else
                    curTestCases[testCaseIndex][dataType] = text
                curTestCases.sort((a, b) => a.number - b.number)
                setTestCases([...curTestCases])
            })
        })
        e.target.value = ""
    }

    const handleCreate = async () => {
        setIsLoading(true)
        const testCasesToCreate = testCases.filter(x => x.input && x.output)
        const api = new TestCaseAPI();
        for (let tc of testCasesToCreate) {
            tc.number = +tc.number
            tc["test_type_id"] = 3; // default
            tc["task_id"] = +props.match.params.id;
            await api.CreateTestCase(tc);
        }
        history.push(`/task/${props.match.params.id}/test_cases`);
    }

    if (isLoading) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Button
                className={classes.btnAddManually}
                variant={"contained"}
                color={"primary"}
                onClick={() => history.push(`/task/${props.match.params.id}/test_cases/add`)}
            ><AddIcon />&nbsp;add test cases manually</Button>
            <div className={classes.uploaderWrapper} >
                <Button color={"primary"} variant={"contained"} className={classes.addBtn} onClick={() => inputUpload.current.click()}>
                    ADD .TXT FILES
                </Button>
                <div className={classes.inputContainer}>
                    <input type={'file'} accept={'.txt'} className={classes.inputFileUpload}
                           ref={inputUpload} onChange={inputFileOnChange} multiple
                    />
                    <div className={classes.inputContent}>
                        <h3 className={classes.inputHeader}>DRAG AND DROP FILES OR SELECT ADD .TXT FILE</h3>
                        <div className={classes.iconWrapper}>
                            <ArrowUpwardRounded className={classes.icon}/>
                        </div>
                        <h3 className={classes.inputHeader}>{"input{number}.txt / output{number}.txt"}</h3>
                    </div>
                </div>
            </div>

            {testCases.length === 0 ? "" :
                <Table aria-label="test cases table">
                    <TableHead>
                        <TableRow data-role={"tableHead"}>
                            <TableCell className={classes.numberCell + " " + classes.headRowCell}>number</TableCell>
                            <TableCell className={classes.inputCell + " " + classes.headRowCell}>input</TableCell>
                            <TableCell className={classes.outputCell + " " + classes.headRowCell}>output</TableCell>
                            <TableCell className={classes.btnCell}>{null}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testCases.map(tc => {
                            return (
                                <TableRow key={tc.number} data-id={tc.id} className={`${tc.input && tc.output ? classes.successRow : ""} ${classes.tableRow}`}>
                                    <TableCell align={"center"} className={classes.numberCell}>{tc.number}</TableCell>
                                    <TableCell className={classes.inputCell}>
                                        {tc.input ? <ReadMore text={tc.input} maxLength={400} maxLinesNumber={5} /> : ""}
                                    </TableCell>
                                    <TableCell className={classes.outputCell}>
                                        {tc.output ? <ReadMore text={tc.output} maxLength={400} maxLinesNumber={5} /> : ""}
                                    </TableCell>
                                    <TableCell className={classes.btnCell}>
                                        <Tooltip title="Delete">
                                            <Button onClick={() => {
                                                setTestCases(prev => prev.filter(testCase => testCase.number !== tc.number))
                                            }}><DeleteIcon /></Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            }

            {testCases.findIndex(item => item.input && item.output) === -1 ? "" :
                <Button variant={"contained"} color={"primary"} className={classes.createBtn} onClick={handleCreate}>
                    {`create ${testCases.filter(x => x.input && x.output).length} new test cases`}
                </Button>
            }

            <Snackbar
                open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(prev => !prev)}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
            >
                <MuiAlert onClose={() => setIsSnackbarOpen(prev => !prev)} severity="error">
                    Invalid file extension / name
                </MuiAlert>
            </Snackbar>
        </Card>
    )
}

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 1300,
        padding: 20
    },
    btnAddManually: {
        float: "right",
        marginBottom: "20px"
    },
    uploaderWrapper: {
        width: "60%",
        margin: "0 auto"
    },
    addBtn: {
        width: "100%",
        fontWeight: 'bold'
    },
    inputContainer: {
        marginTop: 20,
        marginBottom: 20,
        border: `4px dashed ${theme.palette.primary.main}`,
        position: "relative",
        transition: "all .5s",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            border: "4px dashed #ffffff"
        },
        "&:hover $iconWrapper": {
            backgroundPosition: "0 -100%",
            color: theme.palette.primary.main,
        },
        "&:hover $inputHeader": {
            color: "#fff"
        }
    },
    inputFileUpload: {
        position: "absolute",
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        outline: "none",
        opacity: 0,
        cursor: "pointer",
        zIndex: 4
    },
    inputContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    inputHeader: {
        margin: 0,
        color: theme.palette.primary.main,
        transition: "color .5s",
    },
    iconWrapper: {
        height: 150,
        width: 150,
        margin: "10px 0",
        borderRadius: 100,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "4px solid #FFFFFF",
        overflow: "hidden",
        backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, #FFFFFF 50%)`,
        backgroundSize: "100% 200%",
        transition: "all 1s",
        color: "#FFFFFF",
        fontSize: 100,
    },
    icon: {
        fontSize: "100px"
    },
    createBtn: {
        display: "block",
        margin: "0 auto",
        marginTop: "20px"
    },
    // table
    numberCell: {
        width: '50px',
        verticalAlign: "top",
        textAlign: "start"
    },
    inputCell: {
        width: "40%",
        verticalAlign: "top"
    },
    outputCell: {
        width: "40%",
        verticalAlign: "top"
    },
    btnCell: {
        width: "0",
        verticalAlign: "top",
        textAlign: "right",
        padding: 10
    },
    tableRow: {
        transition: "all .3s"
    },
    successRow: {
        backgroundColor: "#C6EDD3",
        border: "2px solid green"
    },
    headRowCell: {
        fontWeight: "bold",
        fontSize: "1rem"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    },
}))