import React, {useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {TaskAPI} from "../../../http/api/TaskAPI";
import Divider from "@material-ui/core/Divider";
import {TestCaseAPI} from "../../../http/api/TestCaseAPI";
import history from "../../../history";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from '@material-ui/icons/Delete';
import {Card} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";


export const AddTestCases = (props) => {
    const classes = useStyles();
    let [task, setTask] = React.useState(null);
    let [testCases, setTestCases] = React.useState([]);
    let [showProgressBar, setShowProgressBar] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)

    useEffect(() => {
        async function fetchTask(id) {
            const api = new TaskAPI();
            return await api.RetrieveTask(id)
        }

        let taskID = props.match.params.id;
        fetchTask(taskID).then((s) => {
            setTask(s)
        })
    }, [props]);

    const handleCreate = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        setShowProgressBar(true);
        const api = new TestCaseAPI();

        for (let tc of testCases) {
            if (tc.number === "") delete tc.number
            else tc.number = +tc.number
            tc["test_type_id"] = 3; // default
            tc["task_id"] = task.id;
            await api.CreateTestCase(tc);
        }

        history.push(`/task/${task.id}/test_cases`);
    }

    if (task === null || isLoading) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <div className={classes.headerBlock}>
                <Typography variant="h5" component="h1">
                    Add tests to task №{task["number"]}
                </Typography>
                <Button
                    color={'primary'}
                    className={classes.fileTestCaseBtn}
                    variant={"contained"}
                    onClick={() => history.push(`/task/${props.match.params.id}/test_cases/add_from_files`)}
                ><AddIcon />&nbsp;add test case from files</Button>
            </div>
            <br/>
            <Divider/>
            {showProgressBar ? <CircularProgress/> :
                <form onSubmit={(event => handleCreate(event))}>
                    <FormControl className={classes.formControl}>
                        {testCases.map((tc, i) => {
                            return <div key={i} className={classes.formRow}>
                                <TextField
                                    label={`Test ${i} number (optional)`}
                                    value={testCases[i].number}
                                    onChange={event => {
                                        let tests = testCases.slice();
                                        tests[i].number = event.target.value;
                                        setTestCases(tests);
                                    }}
                                    className={classes.formField}
                                />
                                <TextField
                                    label={`Test ${i} input`}
                                    value={testCases[i].input}
                                    multiline
                                    onChange={event => {
                                        let tests = testCases.slice();
                                        tests[i].input = event.target.value;
                                        setTestCases(tests);
                                    }}
                                    className={classes.formField}
                                    required
                                />
                                <TextField
                                    label={`Test ${i} output`}
                                    value={testCases[i].output}
                                    multiline
                                    onChange={event => {
                                        let tests = testCases.slice();
                                        tests[i].output = event.target.value;
                                        setTestCases(tests);
                                    }}
                                    className={classes.formField}
                                    required
                                />
                                <Button color="secondary" onClick={() => {
                                        let tests = testCases.slice();
                                        tests.splice(i, 1);
                                        setTestCases(tests);
                                    }}
                                >
                                    <DeleteIcon/>
                                </Button>
                                <br/>
                            </div>
                        })}
                    </FormControl>
                    <br/>
                    <Button type="button" className={classes.addBtn} variant="contained" size="small" onClick={() => {
                        let tc = testCases.slice();
                        tc.push({input: '', output: ''});
                        setTestCases(tc);
                    }}>+</Button>
                    <br/><br/>
                    <Button type="submit" variant="contained" color="primary">Create</Button>
                </form>
            }
        </Card>
    );
}

const useStyles = makeStyles(() => ({
    card: {
        padding: 20,
        width: 1200
    },
    headerBlock: {
        display: "flex",
        justifyContent: "space-between"
    },
    formControl: {
        width: "100%"
    },
    formRow: {
        display: "flex",
        alignItems: "end",
        marginBottom: "10px",
        justifyContent: "center"
    },
    formField: {
        flexGrow: "1",
        marginRight: "10px",
        "&:first-child": {
            width: 0
        }
    },
    addBtn: {
        marginTop: "20px"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}));
