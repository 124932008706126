import React, {useEffect} from 'react'
import {TestCaseAPI} from "../../../http/api/TestCaseAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Card, makeStyles, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import history from "../../../history";

export const EditTestCase = (props) => {
    const classes = useStyles()
    const [testCase, setTestCase] = React.useState(null)
    const [number, setNumber] = React.useState("")
    const [inputValue, setInputValue] = React.useState("")
    const [outputValue, setOutputValue] = React.useState("")

    useEffect(() => {
        async function fetchTestCase(testCaseId) {
            const testCaseApi = new TestCaseAPI()
            return await testCaseApi.RetrieveTestCase(testCaseId)
        }
        fetchTestCase(props.match.params.testcase_id).then((testCase) => {
            setTestCase(testCase)
            setNumber((testCase.number))
            setInputValue(testCase.input)
            setOutputValue(testCase.output)
        })
    }, [props])

    const saveBtnOnClick = () => {
        const testCaseApi = new TestCaseAPI()
        const testCaseObject = {
            input: inputValue,
            output: outputValue,
            number: +number
        }
        testCaseApi.UpdateTestCase(props.match.params.testcase_id, JSON.stringify(testCaseObject)).then(() => {
            history.push({pathname: `/task/${props.match.params.id}/test_cases`});
        })
    }

    if (testCase === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant={"h6"}>{`Editing test case №${testCase.number} of task №${testCase.task.number}`}</Typography>
            <form className={classes.form}>
                <TextField
                    id="number-text-field"
                    label="Number"
                    variant="standard"
                    className={classes.textField}
                    value={number}
                    onChange={event => setNumber(event.target.value)}
                />
                <TextField
                    id="input-text-field"
                    label="Input"
                    variant="standard"
                    className={classes.textField}
                    value={inputValue}
                    onChange={event => setInputValue(event.target.value)}
                    multiline
                />
                <TextField
                    id="output-text-field"
                    label="Output"
                    variant="standard"
                    className={classes.textField}
                    value={outputValue}
                    onChange={event => setOutputValue(event.target.value)}
                    multiline
                />
                <Button
                    variant="contained"
                    color={"primary"}
                    className={classes.saveBtn}
                    onClick={saveBtnOnClick}
                >Save</Button>
            </form>
        </Card>
    )
}

const useStyles = makeStyles(() => ({
    card: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px"
    },
    form: {
        width: "98%",
    },
    textField: {
        width: "95%",
        marginBottom: "20px"
    },
    saveBtn: {
        width: "min-content",
        display: "block"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}))