import React from "react";
import {Route} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {CheckerList} from "../views/checker/CheckerList";
import {ProgrammingLanguageList} from "../views/programming_language/ProgrammingLanguageList";
import {SolutionList} from "../views/solution/SolutionList";
import {TaskList} from "../views/task/TaskList";
import {SolutionView} from "../views/solution/SolutionView";
import {TaskView} from "../views/task/TaskView";
import {ProgrammingLanguageView} from "../views/programming_language/ProgrammingLanguageView";
import {CheckerView} from "../views/checker/CheckerView";
import {Placeholder} from "../views/Placeholder";
import {CheckerCreate} from "../views/checker/CheckerCreate";
import {CheckerEdit} from "../views/checker/CheckerEdit";
import {TaskCreate} from "../views/task/TaskCreate";
import {TaskEdit} from "../views/task/TaskEdit";
import {TestCaseList} from "../views/test_case/TestCaseList";
import {AddTestCases} from "../views/test_case/AddTestCases";
import {EditTestCase} from "../views/test_case/EditTestCase";
import {SolutionListOfOneTask} from "../views/solution/SolutionListOfOneTask";
import {TestCaseView} from "../views/test_case/TestCaseView";
import {AddFileTestCase} from "../views/test_case/AddFileTestCase";

/*
 Контент страницы
 */
export const NestedRouter = () => {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <div className={classes.toolbar}/>
            <Route exact path='/' component={Placeholder}/>

            <Route exact path="/checkers" component={CheckerList}/>
            <Route exact path="/checkers/new" component={CheckerCreate}/>
            <Route exact path="/checker/:id" component={CheckerView}/>
            <Route exact path="/checker/:id/edit" component={CheckerEdit}/>

            <Route exact path="/programming_languages" component={ProgrammingLanguageList}/>
            <Route exact path="/programming_language/:id" component={ProgrammingLanguageView}/>

            <Route exact path="/solutions" component={SolutionList}/>
            <Route exact path="/solution/:uuid" component={SolutionView}/>

            <Route exact path="/tasks" component={TaskList}/>
            <Route exact path="/tasks/new" component={TaskCreate}/>
            <Route exact path="/task/:id" component={TaskView}/>
            <Route exact path="/task/:id/edit" component={TaskEdit}/>
            <Route exact path="/task/:id/solutions" component={SolutionListOfOneTask}/>
            <Route exact path="/task/:id/add_tests" component={AddTestCases}/>
            <Route exact path="/task/:id/test_cases" component={TestCaseList}/>
            <Route exact path="/task/:id/test_cases/:testcase_id/edit" component={EditTestCase}/>
            <Route exact path="/task/:id/test_cases/add" component={AddTestCases}/>
            <Route exact path="/task/:id/test_cases/add_from_files" component={AddFileTestCase}/>
            <Route exact path="/task/:id/test_cases/inspect/:testcase_id" component={TestCaseView}/>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
