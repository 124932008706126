import {HTTP} from "../http-common";

export class SolutionAPI {
    QuerySolution(start, end, sort='id', order='DESC', task = "") {
        order = order.toUpperCase()
        return new Promise((resolve) => {
            HTTP.axios.get(`/solution?_start=${start}&_end=${end}&_order=${order}&_sort=${sort}&task=${task}`)
                .then(response => {
                    localStorage.setItem("total-count", response.headers["x-total-count"]);
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    CreateSolution(solution) {
        return new Promise((resolve) => {
            HTTP.axios.post(`/solution`, solution)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    RetrieveSolution(uuid) {
        return new Promise((resolve) => {
            HTTP.axios.get(`/solution/${uuid}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    UpdateSolution(uuid, solution) {
        return new Promise((resolve) => {
            HTTP.axios.put(`/solution/${uuid}`, solution)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }

    DeleteSolution(uuid) {
        return new Promise((resolve) => {
            HTTP.axios.delete(`/solution/${uuid}`)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                HTTP.handleError(error);
            });
        });
    }
}
