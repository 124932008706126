import React, {useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {TaskAPI} from "../../../http/api/TaskAPI";
import {ViewFooter} from "../ViewFooter";
import Divider from "@material-ui/core/Divider";
import history from "../../../history";
import {ButtonGroup, Card, Dialog, DialogActions, DialogTitle, Slide} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const TaskView = (props) => {
    const classes = useStyles();
    let [task, setTask] = React.useState(null);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    let deleteTask= async () => {
        const api = new TaskAPI();
        await api.DeleteTask(task.id);
        history.push('/tasks');
    }

    useEffect(() => {
        async function fetchTask(id) {
            const api = new TaskAPI();
            return await api.RetrieveTask(id)
        }

        let taskID = props.match.params.id;
        fetchTask(taskID).then((s) => {
            setTask(s)
        })
    }, [props]);

    if (task === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <Typography variant="h5" component="h1">
                Task №{task["number"]}
            </Typography>
            <br/>
            <Divider/>
            <br/>
            <Typography variant="body1" color="textPrimary" component="p">
                Is interactive: {task["is_interactive"].toString()} <br/>
                Number: {task.number} {task.version ? `(v${task.version})` : ''} <br/>
            </Typography>
            <br />
            <Typography>
                Checker: <span onClick={() => history.push(`/checker/${task["checker"].id}`)}
                            className="link-style">{task["checker"].name}</span>
            </Typography>
            <br/>

            <ButtonGroup>
                <Button variant="contained" className={classes.solutionsBtn} onClick={() => history.push(`/task/${task.id}/solutions`)}>
                    Solutions
                </Button>
                <Button variant="contained" className={classes.testCaseBtn} onClick={() => history.push(`/task/${task.id}/test_cases`)}>
                    Test cases
                </Button>
                <Button variant="contained" color="primary" onClick={() => history.push(`/task/${task.id}/edit`)}>
                    Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => setIsDialogOpen(true)}>
                    Delete
                </Button>
            </ButtonGroup>
            <br/>
            <br/>
            <ViewFooter entity={task}/>

            <Dialog
                open={isDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setIsDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.dialogHeader}>{"Are you sure?"}</DialogTitle>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={deleteTask} style={{color: "red"}}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 640,
        padding: 20
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    testCaseRow: {
        cursor: "pointer"
    },
    testCaseBtn: {
        backgroundColor: "#ff5722",
        '&:hover': {
            backgroundColor: "#b23c17"
        },
        color: "#fff"
    },
    dialogHeader: {
        minWidth: "300px",
        display: 'flex',
        justifyContent: "center"
    },
    dialogActions: {
        display: 'flex',
        justifyContent: "space-between"
    },
    solutionsBtn: {
        backgroundColor: "lightgreen",
        '&:hover': {
            backgroundColor: "#a2cf6e"
        }
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    }
}));
