import React, {useEffect, useState} from "react";
import {TestCaseAPI} from "../../../http/api/TestCaseAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import {ButtonGroup, Card, Dialog, DialogActions, DialogTitle, makeStyles, Slide, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import history from "../../../history";

export const TestCaseView = (props) => {

    const [testCase, setTestCase] = useState(null)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const classes = useStyles()

    useEffect(() => {
        async function fetchTestCase(testCaseId) {
            const testCaseApi = new TestCaseAPI()
            return await testCaseApi.RetrieveTestCase(testCaseId)
        }
        fetchTestCase(props.match.params.testcase_id).then((testCase) => {
            setTestCase(testCase)
        })
    }, [props.match.params.testcase_id])

    const deleteBtnOnClick = () => {
        setIsDialogOpen(false)
        const api = new TestCaseAPI()
        api.DeleteTestCase(testCase.id).then(() => {
            history.push(`/task/${props.match.params.id}/test_cases`)
        })
    }

    if (testCase === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <Card variant="outlined" className={classes.card}>
            <h2 className={classes.mainHeader}>Test case №{testCase.number} of task №{testCase.task.id}</h2>
            <div>
                <div>
                    <h3>Input</h3>
                    <p className={classes.textContent}>{testCase.input}</p>
                </div>
                <div>
                    <h3>Output</h3>
                    <p className={classes.textContent}>{testCase.output}</p>
                </div>
            </div>
            <ButtonGroup>
                <Tooltip title="Edit">
                    <Button
                        variant="contained" color="primary"
                        onClick={() => history.push(`/task/${props.match.params.id}/test_cases/${testCase.id}/edit`)}
                    >Edit</Button>
                </Tooltip>
                <Tooltip title="Delete">
                    <Button
                        variant="contained" color="secondary"
                        onClick={() => {setIsDialogOpen(true)}}
                    >Delete</Button>
                </Tooltip>
            </ButtonGroup>

            <Dialog
                open={isDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setIsDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.dialogHeader}>{"Are you sure?"}</DialogTitle>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={() => deleteBtnOnClick()} style={{color: "red"}}>Delete</Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    card: {
        padding: 20
    },
    mainHeader: {
        marginTop: 0
    },
    textContent: {
        whiteSpace: "pre-wrap"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    },
    dialogHeader: {
        minWidth: "300px",
        display: 'flex',
        justifyContent: "center"
    },
    dialogActions: {
        display: 'flex',
        justifyContent: "space-between"
    }
}))