import React, {useCallback, useEffect} from "react";

import {makeStyles} from '@material-ui/core/styles';
import {TaskAPI} from "../../../http/api/TaskAPI";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import history from "../../../history";
import TableContainer from "@material-ui/core/TableContainer";
import {
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogTitle,
    Slide,
    Tooltip
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import {TestCaseAPI} from "../../../http/api/TestCaseAPI";
import {ReadMore} from "../../common/ReadMore";


export const TestCaseList = (props) => {
    const classes = useStyles();
    const [testCasesState, setTestCasesState] = React.useState({
        testCases: [],
        wasChange: false
    })
    const testCasesRef = React.useRef([])
    let isMouseHeld = React.useRef(false)
    let testCaseToMoveId = React.useRef(null)
    const [taskNumber, setTaskNumber] = React.useState(null)
    let draggingRow = React.useRef(null)

    // for delete confirmation dialog
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const testCaseIdToDelete = React.useRef(null)

    useEffect(() => {
        const taskId = props.match.params.id;

        async function fetchData(id) {
            const apiTask = new TaskAPI();
            const task = await apiTask.RetrieveTask(id);

            const apiTestCase = new TestCaseAPI()
            const testCases = await apiTestCase.QueryTestCaseOfOneTask(0, 9999999, id)
            return {task: task, testCases: testCases}
        }

        fetchData(taskId).then(data => {
            testCasesRef.current = data.testCases
            setTestCasesState({
                testCases: data.testCases,
                wasChange: false
            })
            setTaskNumber(data.task.number)
            isMouseHeld.current = false
            testCaseToMoveId.current = null
        })
    }, [props.match.params.id]);

    const windowMouseupListener = useCallback(event => {
        if (isMouseHeld.current) {
            isMouseHeld.current = false
            let eventTargetTR = event.target;
            while (eventTargetTR.tagName !== "TR" && eventTargetTR.parentElement) {
                eventTargetTR = eventTargetTR.parentElement
            }
            if (eventTargetTR.tagName === "TR") {
                const neighborTestCaseId = eventTargetTR.dataset.id
                const currentTargetRect = eventTargetTR.getBoundingClientRect();
                const height = currentTargetRect.height
                const event_offsetY = event.clientY - currentTargetRect.top;
                let isInsertBefore = true
                if (event_offsetY > height / 2) {
                    isInsertBefore = false
                }
                eventTargetTR.classList.remove(classes.hoverAfter)
                eventTargetTR.classList.remove(classes.hoverBefore)
                if (neighborTestCaseId !== testCaseToMoveId.current) {
                    const testCaseToMoveIndex = testCasesRef.current.findIndex(item => +item.id === +testCaseToMoveId.current)
                    const testCaseToMoveItem = testCasesRef.current.find(item => +item.id === +testCaseToMoveId.current)
                    testCasesRef.current.splice(testCaseToMoveIndex, 1)   // deleting test case at the old place
                    const newNeighborIndex = testCasesRef.current.findIndex(item => +item.id === +neighborTestCaseId)
                    testCasesRef.current.splice(newNeighborIndex + !isInsertBefore, 0, testCaseToMoveItem)  // adding test case at the new place
                    setTestCasesState({
                        wasChange: true,
                        testCases: testCasesRef.current
                    })
                    draggingRow.current.classList.add(classes.movedRow)
                }
                draggingRow.current.classList.remove(classes.draggingRow)
            }
        }
    }, [classes.hoverBefore, classes.hoverAfter, classes.draggingRow, classes.movedRow])

    const windowMouseoverListener = useCallback(event => {
        if (!isMouseHeld.current) return;
        if (event.y > 0.9 * window.innerHeight) {
            window.scrollBy({
                top: 700,
                left: 0,
                behavior: "smooth"
            })
        }
        else if (event.y < 0.15 * window.innerHeight) {
            window.scrollBy({
                top: -700,
                left: 0,
                behavior: "smooth"
            })
        }
    }, [])

    useEffect(() => {
        window.addEventListener('mouseup', windowMouseupListener)
        window.addEventListener('mouseover', windowMouseoverListener)

        return () => {
            window.removeEventListener('mouseup', windowMouseupListener)
            window.removeEventListener('mouseover', windowMouseoverListener)
        }
    }, [windowMouseupListener, windowMouseoverListener])

    const moveBtn_MouseDown = (event) => {
        isMouseHeld.current = true
        let parentElement = event.target.parentElement
        while (parentElement.tagName !== "TR") {
            parentElement = parentElement.parentElement
        }
        testCaseToMoveId.current = parentElement.dataset.id
        parentElement.classList.add(classes.draggingRow)
        draggingRow.current = parentElement
    }

    const moveBtn_MouseUp = () => {
        draggingRow.current.classList.remove(classes.draggingRow)
    }

    const rowMouseMove = (event) => {
        if (isMouseHeld.current) {
            if (event.target.parentElement.tagName === "TR") {
                if (event.target.parentElement.dataset.role === "tableHead") return;
                const currentTargetRect = event.target.parentElement.getBoundingClientRect();
                const height = currentTargetRect.height
                const event_offsetY = event.clientY - currentTargetRect.top;
                if (event_offsetY > height / 2) {
                    event.target.parentElement.classList.add(classes.hoverBefore)
                    event.target.parentElement.classList.remove(classes.hoverAfter)
                }
                else {
                    event.target.parentElement.classList.add(classes.hoverAfter)
                    event.target.parentElement.classList.remove(classes.hoverBefore)
                }
            }
        }
    }

    const rowMouseLeave = event => {
        if (isMouseHeld.current) {
            let target = event.target
            while (target.tagName !== "TR") target = target.parentElement
            target.classList.remove(classes.hoverAfter)
            target.classList.remove(classes.hoverBefore)
        }
    }

    const deleteBtnOnClick = () => {
        setIsDialogOpen(false)
        setTestCasesState({
            testCases: null,
            wasChange: false
        })
        const api = new TestCaseAPI()
        api.DeleteTestCase(testCaseIdToDelete.current).then(() => {
            const taskId = props.match.params.id;
            const apiTestCase = new TestCaseAPI()
            return apiTestCase.QueryTestCaseOfOneTask(0, 9999999, taskId)
        }).then((testCases) => {
            setTestCasesState({
                testCases: testCases,
                wasChange: false
            })
        })
    }

    const saveChangesBtnOnClick = () => {
        const newOrder = testCasesRef.current.map(item => item.id)
        const api = new TestCaseAPI()
        api.ReorderTestCase(props.match.params.id, newOrder).then(() => {
            window.location.reload()
        })
    }

    if (testCasesState.testCases === null || taskNumber === null) return <CircularProgress className={classes.circularProgress} size={80}/>
    else return (
        <TableContainer component={Paper}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6">{`Test cases of task №${taskNumber}`}</Typography>
                <ButtonGroup>
                    {testCasesState.wasChange ?
                        <Button
                            variant={"contained"}
                            className={classes.saveBtn}
                            onClick={saveChangesBtnOnClick}
                        ><SaveIcon />&nbsp;Save changes</Button>
                        :
                        null
                    }
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => history.push(`/task/${props.match.params.id}/test_cases/add`)}
                    ><AddIcon />&nbsp;add test cases manually</Button>
                    <Button
                        className={classes.fileTestCaseBtn}
                        variant={"contained"}
                        onClick={() => history.push(`/task/${props.match.params.id}/test_cases/add_from_files`)}
                    ><AddIcon />&nbsp;add test case from files</Button>
                </ButtonGroup>
            </Toolbar>
            <Table aria-label="test cases table" onMouseMove={rowMouseMove} className={classes.table}>
                <TableHead>
                    <TableRow data-role={"tableHead"}>
                        <TableCell className={classes.numberCell}>number</TableCell>
                        <TableCell>input</TableCell>
                        <TableCell>output</TableCell>
                        <TableCell>{null}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {testCasesState.testCases.sort((a, b) => a.number - b.number).map(tc => {
                        return (
                            <TableRow key={tc.id} onMouseLeave={rowMouseLeave} data-id={tc.id}>
                                <TableCell align={"center"} className={classes.numberCell}>{tc.number}</TableCell>
                                <TableCell className={classes.inputCell}>
                                    <ReadMore text={tc.input} maxLength={130} maxLinesNumber={5} />
                                </TableCell>
                                <TableCell className={classes.outputCell}>
                                    <ReadMore text={tc.output} maxLength={130} maxLinesNumber={5} />
                                </TableCell>
                                <TableCell className={classes.btnCell}>
                                    <Tooltip title="Inspect">
                                        <Button
                                            onClick={() => history.push(`/task/${props.match.params.id}/test_cases/inspect/${tc.id}`)}
                                        ><VisibilityIcon/></Button>
                                    </Tooltip>
                                    <Tooltip title="Move">
                                        <Button onMouseDown={moveBtn_MouseDown} onMouseUp={moveBtn_MouseUp}><DoubleArrowIcon/></Button>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                        <Button onClick={() => history.push(`/task/${props.match.params.id}/test_cases/${tc.id}/edit`)}
                                        ><EditIcon /></Button>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <Button onClick={() => {
                                            setIsDialogOpen(true)
                                            testCaseIdToDelete.current = tc.id
                                        }}><DeleteIcon /></Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>

            <Dialog
                open={isDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setIsDialogOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.dialogHeader}>{"Are you sure?"}</DialogTitle>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                    <Button onClick={() => deleteBtnOnClick()} style={{color: "red"}}>Delete</Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    numberCell: {
        width: '90px',
        verticalAlign: "top"
    },
    inputCell: {
        minWidth: "40%",
        verticalAlign: "top"
    },
    outputCell: {
        minWidth: "40%",
        verticalAlign: "top"
    },
    btnCell: {
        width: "18%",
        verticalAlign: "top",
        padding: 10
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    },
    hoverBefore: {
        borderBottom: "3px dashed orange"
    },
    hoverAfter: {
        borderTop: "3px dashed orange"
    },
    saveBtn: {
        backgroundColor: "lightgreen"
    },
    dialogHeader: {
        minWidth: "300px",
        display: 'flex',
        justifyContent: "center"
    },
    dialogActions: {
        display: 'flex',
        justifyContent: "space-between"
    },
    circularProgress: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transition: "translate(-50%, -50%)"
    },
    draggingRow: {
        backgroundColor: "lightgoldenrodyellow !important"
    },
    movedRow: {
        backgroundColor: "#E7FFEC"
    },
    fileTestCaseBtn: {
        backgroundColor: "#6E84FF",
        "&:hover": {
            backgroundColor: "#5D70D8"
        }
    }
}));
