import React from "react";
import PropTypes from 'prop-types';

import {Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

export const ViewFooter = (props) => {
    const {entity} = props;

    return (
        <div>
            <Divider />
            <br />
            <Typography variant="body2" color="textSecondary" component="p">
                Created: {moment(entity["created_at"]).format('LLL')} <br />
                Updated: {moment(entity["updated_at"]).format('LLL')} <br />
            </Typography>
            <br/>
        </div>
    )
}

ViewFooter.propTypes = {
    entity: PropTypes.object.isRequired
}
