import axios from 'axios';

export class AuthAPI {
    LoginUser(username, password) {
        return new Promise((resolve, reject) => {
            axios.post(window._env_.API_URL + `/api/auth/login`, {
                username: username,
                password: password
            }).then(response => {
                console.log(response);
                resolve(response.data);
            }).catch(error => {
                console.log(error.response);
                reject(error.response.data);
            })
        })
    }
}
